
// ===================================================================

import React from 'react'
import ReactDOM from 'react-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import App from './components/App'

// ===================================================================

const GlobalStyle = createGlobalStyle`

	$primary-color: green;

	html {
		font-family: Helvetica, sans-serif;
		background: #eee;
		position: relative;
	}
	html, body, #root {
		height: 100%;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	a {
		text-decoration: none;
	}
	.Header {
		background: white;
		box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
		padding: 20px 10px;
	}
	.Content {
		padding: 10px;
	}
	button,
	.ButtonWrap a {
		border: 1px solid #0151ca !important;
		background: #1373ec;
		color: white;
		margin: 2px;
		padding: 5px 10px;
		font-weight: bold;
		font-size: 15px;
		border-radius: 4px;
		margin-bottom: 10px;
	}
	.ButtonWrap a {
		display: inline-block;
	}
`

const theme = {
	something: 'red'
}

// ===================================================================

ReactDOM.render(
	<React.Fragment>
		<GlobalStyle/>
		<ThemeProvider theme={ theme }>
			<App/>
		</ThemeProvider>
	</React.Fragment>
, document.getElementById("root"))

// ===================================================================
