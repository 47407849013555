
import React from 'react'
import '@babel/polyfill'
import styled from 'styled-components'

import Layout from '../components/Layout'
import Monitor from '../components/Monitor'

import API from "../API";
import {useDropzone} from "react-dropzone";
import imageCompression from "browser-image-compression";

const MonitorsLayout = styled(Layout)`
	.container {
        display: flex;
        flex-wrap: wrap;  
        gap: 28px;
        justify-content: center;
    }
  
    .navigation {
        padding: 16px;
        width: 100%;
        background: white;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }
      
    .navigation div {
        display: flex;  
        gap: 20px;
    }
  
    button {
        margin: 0;
        padding: 10px 20px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }
  
    button:hover {
        filter: brightness(0.8);
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        overflow: scroll !important;
    }
  
    .modalContent {
        width: 60%;
        margin: auto;
        padding: 0 8px;
        background: white;
        border-radius: 8px;
    }
  
    .uploadModal {
      width: 35%;
      padding: 8px;
    }
  
    .modal h3 {
        padding: 8px;
    }
  
    .dropzone {
        justify-content: center;
        display: flex;
    }
  
    .dropzone label {
        width: 600px;
        height: 300px;
        border-radius: 8px;
        border: 2px dashed #ccc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: #f8f8f8;
        transition: all 0.2s ease-in-out;
    }
  
    .dropzone label:hover {
      background: #f3f4f6;
    }
  
    .dropzone label div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: transparent;
    }
  
    .dropzone label div i {
        font-size: 40px;
        color: #ccc;
    }
  
    .dropzone label div p {
        color: #ccc;
        font-size: 16px;
        margin-top: 10px;
    }
  
    .dropzone label input {
        display: none;
    }
  
    .modalActions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
    }
    
    .saveBtn {
      justify-content: flex-end;
    }
  
    .modalActions span {
        font-size: 14px;
        color: #ccc;
    }
  
    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 30px;
    }
  
    .loading span {
        font-size: 20px;
    }
  
  .modalHeader {
    position: sticky;
    display: flex;
    justify-content: space-between;
    top: 0;
    height: 70px;
    z-index: 5;
    background: white;
  }
  
  .modalHeader h3 {
    align-items: center;
    margin-top: 20px;
  }
  
  .modalHeader button {
    margin-top: 15px;
    margin-right: 10px;
    height: 40px;
  }
  
  .imageOverlay {
    background-color: rgba(0, 0, 0, 0.8);
    max-height: 240px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;
    padding: 2px;
    font-size: 90%;
    pointer-events: auto;
    cursor: pointer;
  }
  
  .seconds {
    padding: 8px;
    width: 80px;
    margin-right: 6px;
  }
  
  .secondsLabel {
    margin-right: 14px;
    font-weight: bold;
  }
  
  .hoverText {
    position: absolute;
    bottom: 10%;
    z-index: 10;
    color: #fff;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    display: block; /* You need to set this based on your logic */
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
  }
  
  @media(max-width: 1080px) {
   .modalHeader {
      flex-direction: column;
      justify-content: center;
      height: 100px;
      padding: 4px;
      text-align: center;
      position: sticky;
   }
    .modalHeader h3 {
      align-items: center;
      margin-top: 5px;
      text-align: center;
      font-size: 20px;
    }

    .modalHeader button {
      margin-top: 0;
      height: 40px;
    }
    .modalContent {
      width: 90%;
    }
    .container {
      justify-content: center;
    }
    .hoverText {
      text-align: center;
      font-size: 14px;
    }
    .navigation {
      gap: 6px;
    }
    .navigation div {
      gap: 6px;
    }
  }
  
  .namedaySettings {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    gap: 10px;
  }
  
  .namedayLabel {
    padding-bottom: 10px;
  }
  
  .notSelected {
    background: #74a7e8;
    border: 1px solid #74a7e8 !important;
  }
  
  .selected {
    background: #1472ec
  }
  
  .settingsContent {
    padding: 8px;
  }
  
  
  
`
const Dropzone = ({ onFileChange, selectedFiles }) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            onFileChange({ target: { files: acceptedFiles } });
        },
    });

    return (
        <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <label>
                <div>
                    {selectedFiles.length > 0 ? (
                        selectedFiles.map((file, i) => (
                            <p key={i}>
                                <b>{file.name}</b>
                            </p>
                        ))
                    ) : (
                        <>
                            <i className="fas fa-cloud-upload-alt"></i>
                            <p>
                                <b>Click to upload</b> or drag and drop
                            </p>
                        </>
                    )}
                    <p>Only PNG, JPG, JPEG, or GIF types are allowed</p>
                </div>
            </label>
        </div>
    );
};
export default class Monitors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isUploadModalOpen: false,
            isSettingsModalOpen: false,
            isLoading: false,
            selectedFiles: [],
            imagesUploaded: 0,
            namedayMonitor: 3,
            bigTv: 1,
        };

        this.toggleUploadModal = this.toggleUploadModal.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.sendFilesToServer = this.sendFilesToServer.bind(this);
        this.toggleSettingsModal = this.toggleSettingsModal.bind(this);
    }

    async getSettings() {
        try {
            const response = await fetch('https://hex.lv/chromecast/?getSettings', {
                method: 'GET',
            });
            const responseJson = await response.json();
            console.log(responseJson)
            if (response.ok) {
                this.setState(() => ({
                    namedayMonitor: parseInt(responseJson.nameday),
                    bigTv: parseInt(responseJson.large_tv),
                }));
            }
        } catch (error) {
            console.error(`Error sending settings to the server:`, error);
        }
    }

    toggleUploadModal() {
        this.setState((prevState) => ({
            isUploadModalOpen: !prevState.isUploadModalOpen,
        }));
    };

    toggleSettingsModal() {
        this.setState((prevState) => ({
            isSettingsModalOpen: !prevState.isSettingsModalOpen,
        }));
    }


    componentDidMount() {
        document.addEventListener('keydown', this.handleEscapeKey);
        this.getSettings()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEscapeKey);
    }

    handleEscapeKey(event) {
        if (event.key === 'Escape') {
            this.setState(() => ({
                isUploadModalOpen: false,
                isSettingsModalOpen: false
            }));
        }
    }

    onFileChange(event) {
        const selectedFiles = Array.from(event.target.files);
        this.setState({
            selectedFiles,
            imagesUploaded: event.target.files.length,
            isLoading: true,
        });
        console.log(selectedFiles)
    };

    handleNamedayChange(namedayId) {
        this.setState(() => ({
            namedayMonitor: namedayId,
        }));
    }

    handleBigTv(bigTv) {
        this.setState(() => ({
            bigTv: bigTv,
        }));
    }

    async saveSettings() {
        try {
            const formData = new FormData();
            formData.append('nameday', this.state.namedayMonitor);
            formData.append('large_tv', this.state.bigTv);
            await fetch('https://hex.lv/chromecast/', {
                method: 'POST',
                body: formData,
            });
        } catch (error) {
            console.error(`Error sending settings to the server:`, error);
        }

        this.setState((prevState) => ({
            isSettingsModalOpen: !prevState.isSettingsModalOpen,
        }));
        fetch('http://194.19.232.194:3388/chromecast/chromecaster/?refresh=true', {
            method: 'GET',
            cors: 'no-cors',
        })
        window.location.reload();
    }

    async handleRefresh() {
        fetch('http://194.19.232.194:3388/chromecast/chromecaster/?refresh=true', {
            method: 'GET',
            cors: 'no-cors',
        })
    }

    async sendFilesToServer(files) {
        const selectedFiles = Array.from(this.state.selectedFiles);
        console.log(selectedFiles)

        const promises = selectedFiles.map(async (file, index) => {
            const compressedFile = await imageCompression(file, {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            });
            const img = new Image();
            img.src = URL.createObjectURL(file);

            await new Promise((resolve) => {
                img.onload = resolve;
            });

            const formData = new FormData();
            formData.append('user', 1);
            formData.append('data', compressedFile.name);
            formData.append('type', compressedFile.type);
            formData.append('width', img.width);
            formData.append('height', img.height);
            formData.append('file', compressedFile);

            console.log(formData.get('user'), formData.get('data'), formData.get('type'), formData.get('width'), formData.get('height'), formData.get('file'));

            try {
                const response = await fetch('https://hex.lv/chromecast/', {
                    method: 'POST',
                    body: formData,
                });
                const responseJson = await response.json();
                if (response.ok) {
                    console.log(`File ${index + 1} uploaded successfully`);
                } else {
                    console.error(`File ${index + 1} upload failed: ${responseJson}`);
                }
            } catch (error) {
                console.error(`Error sending file ${index + 1} to the server:`, error);
            }
        });

        await Promise.all(promises)
            .then(() => {
                this.setState({
                    selectedFiles: [],
                    imagesUploaded: 0,
                    isLoading: false,
                });
                this.toggleUploadModal();
            });
    }


    render() {
        return (
            <MonitorsLayout title="Chromecast" >
                <div className="container">
                    <div className="navigation">
                        <div>
                            <button onClick={() => this.toggleUploadModal()} >
                                Upload
                            </button>
                        </div>
                        <div>
                            <button onClick={() => this.handleRefresh()}>
                                Refresh
                            </button>
                            <button onClick={() => this.toggleSettingsModal()}>
                                Settings
                            </button>
                        </div>
                    </div>
                    <Monitor url={this.state.namedayMonitor === 1 ? "https://hex.lv/chromecast/namedays/" : "https://hex.lv/chromecast/vertical/?monitor=1"}
                             title="1"
                             type="vertical"
                             monitorId={1}
                    ></Monitor>
                    <Monitor url={this.state.namedayMonitor === 2 ? "https://hex.lv/chromecast/namedays/" : "https://hex.lv/chromecast/vertical/?monitor=2"}
                             title="2"
                             type="vertical"
                             monitorId={2}
                    ></Monitor>
                    <Monitor url={this.state.namedayMonitor === 3 ? "https://hex.lv/chromecast/namedays/" : "https://hex.lv/chromecast/vertical/?monitor=3"}
                             title="3"
                             type="vertical"
                             monitorId={3}
                    ></Monitor>
                    <Monitor  url={this.state.bigTv === 0 ? "https://hex.lv/chromecast/off.php" : "https://hex.lv/chromecast/horizontal/"}
                             title="4"
                             type="horizontal"
                             monitorId={4}
                             disabled
                    ></Monitor>
                </div>
                {this.state.isUploadModalOpen && (
                    <div className="modal" onClick={() => this.toggleUploadModal()}>
                        <div className="modalContent uploadModal" onClick={(e) => e.stopPropagation()}>
                            <h3>Upload new images</h3>
                            <Dropzone onFileChange={this.onFileChange} selectedFiles={this.state.selectedFiles} />
                            <div className="modalActions">
                                {this.state.isUploadModalOpen && (
                                    <>
                                        <span>{this.state.imagesUploaded} images are ready to be uploaded</span>
                                        <button onClick={this.sendFilesToServer}>Upload</button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {this.state.isSettingsModalOpen && (
                    <div className="modal" onClick={() => this.toggleSettingsModal()}>
                        <div className="modalContent uploadModal" onClick={(e) => e.stopPropagation()}>
                            <h3>Chromecast settings</h3>
                            <div className="settingsContent">
                                <label htmlFor="namedaySettings" className="namedayLabel" style={{paddingBottom: '20px'}}>Select name day display</label>
                                <div id="namedaySettings" className="namedaySettings" style={{paddingBottom: '20px'}}>
                                    <button
                                        key={0}
                                        onClick={() => this.handleNamedayChange(0)}
                                        className={this.state.namedayMonitor === 0 ? 'selected' : 'notSelected'}
                                    >
                                        Disabled
                                    </button>
                                    <button
                                        key={1}
                                        onClick={() => this.handleNamedayChange(1)}
                                        className={this.state.namedayMonitor === 1 ? 'selected' : 'notSelected'}
                                    >
                                        1
                                    </button>
                                    <button
                                        key={2}
                                        onClick={() => this.handleNamedayChange(2)}
                                        className={this.state.namedayMonitor === 2 ? 'selected' : 'notSelected'}
                                    >
                                        2
                                    </button>
                                    <button
                                        key={3}
                                        onClick={() => this.handleNamedayChange(3)}
                                        className={this.state.namedayMonitor === 3 ? 'selected' : 'notSelected'}
                                    >
                                        3
                                    </button>
                                </div>

                                <label htmlFor="bigtvSettings" className="bigtvLabel" style={{paddingBottom: '20px', paddingTop: '25px'}}>Chromecast for Big TV</label>
                                <div id="bigtvSettings" className="namedaySettings">
                                    <button
                                        key={0}
                                        onClick={() => this.handleBigTv(0)}
                                        className={this.state.bigTv === 0 ? 'selected' : 'notSelected'}
                                    >
                                        Disabled
                                    </button>
                                    <button
                                        key={1}
                                        onClick={() => this.handleBigTv(1)}
                                        className={this.state.bigTv === 1 ? 'selected' : 'notSelected'}
                                    >
                                        Enabled
                                    </button>
                                </div>

                                <div className="modalActions saveBtn">
                                    <button onClick={() => this.saveSettings()}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </MonitorsLayout>
        )
    }
}
