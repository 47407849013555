import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Layout from '../components/Layout'
import {Checkbox, Form, HiddenInput, Select, Submit, TextInput} from '../components/Form'
import Modal from '../components/Modal'
import ReactSelect from 'react-select'

import API from '../API'

const UserLayout = styled(Layout)`
	.InnerBox {
		max-width: 500px;
		flex-grow: 1;
	}
	h2 {
		margin-bottom: 10px;
		font-size: 20px;
	}
	.UserImage {
		width: 150px;
		height: 150px;
		background: gray;
		background-size: cover;
		background-position: center center;
		border-radius: 60px;
		margin: 0 auto;
		margin-bottom: 10px;
	}
	.CompanyEditor {
		margin-top: 30px;
		.Entries {
			.CompanyEditorEntry {
				margin-bottom: 20px;
				border: 1px solid #ccc;
				background: #fff;
				padding: 4px;
				cursor: pointer;
				.Name {
					font-weight: bold;
				}
				.Active {
					color: green;
					font-weight: bold;
				}
				.Inactive {
					color: red;
				}
			}
		}
	}
	.PermissionEditor {
		.Permission {
			line-height: 30px;
			button {
				margin-left: 10px;
				background-color: red;
				font-size: 12px;
				padding: 0 6px;
			}
		}
	}
`

export default class User extends React.Component {
	get isNew() {
		return (this.userId == 'new')
	}
	get userId() {
		return this.props.match.params.userId
	}
	componentDidMount() {
		this.loadData()
	}
	loadData() {
		if(this.isNew) {
			this.setState({ user: { companies: [], permissions: []}, globalPermissions: null, reservaCompanies: [], isReservaAdmin: false  })
		} else {
			this.setState({ user: null, globalPermissions: null, reservaCompanies: [], isReservaAdmin: false})
			API.shared.get('users/' + this.userId).then(data => {
				this.setState({ user: data.user, isReservaAdmin: data.user.isReservaAdmin })
				if(data.user.reservaCompanies ) {
					this.setState({
						reservaCompanies:
							data.user.reservaCompanies.map(company => ({
								value: company,
								label: API.shared.companyName(company)
							}))
					})
				}
			})
			API.shared.get('permissions').then(data => {
				this.setState({ globalPermissions: data })
			})
		}
	}

	handleReservaCompaniesChange(selectedOptions) {
		this.setState({ reservaCompanies: selectedOptions })
	}
	handleIsAdminChange() {
		this.setState(prevState => ({
			isReservaAdmin: !prevState.isReservaAdmin
		}));
	}

	onSubmit(formData) {
		formData.reservaCompanies = this.state.reservaCompanies.map(option => option.value);

		return new Promise((resolve, reject) => {
			let endpoint
			if(this.isNew) {
				endpoint = 'users'
			} else {
				endpoint = 'users/' + this.userId
			}
			API.shared.post(endpoint, formData).then(data => {
				if(data.success) {
					if(this.isNew) {
						this.props.history.push('/users/' + data.userId)
					}
					this.loadData()
					resolve()
				} else {
					reject('Failed!')
				}
			})
		})
	}
	onCompanySubmit(formData) {
		return new Promise((resolve, reject) => {
			API.shared.post('users/' + this.userId + '/company', formData).then(data => {
				if(data.success) {
					this.loadData()
					resolve()
				} else {
					reject('Failed!')
				}
			})
		})
	}
	onAddCompany() {
		this.onEditCompany(null)
	}
	onEditCompany(company) {
		let companyOptions = {}
		for(let id in API.shared.companies) {
			companyOptions[id] = API.shared.companies[id].name
		}
		let isNew = !company
		if(isNew) {
			company = {
				companyId: 0,
				startDate: moment().format('YYYY-MM-DD'),
				active: true,
				vacationTransfer: 0,
				email: null
			}
			if(API.shared.hasPermission('PF_USER_ADMIN') && !API.shared.hasPermission('DG_USER_ADMIN')) {
				company.companyId = 25
			}
		}
		Modal.present(
			<Form onSubmit={ formData => this.onCompanySubmit(formData) }>
				<Select label="Company" name="companyId" options={ companyOptions } defaultValue={ company.companyId }/>
				<TextInput label="Company Email" name="email" defaultValue={ company.email }/>
				<TextInput label="Start Date" name="startDate" defaultValue={ company.startDate }/>
				<TextInput label="Vacation Transfer" name="vacationTransfer" defaultValue={ company.vacationTransfer }/>
				<Checkbox label="Active" name="active" defaultValue={ company.active == 1 }/>
				{ !isNew && <HiddenInput name="entryId" value={ company.entryId }/> }
				<Submit submit label={ isNew ? 'Add User To Company' : 'Save Changes' }/>
			</Form>
		)
	}
	togglePermission(event, perm) {
		const params = {
			permission: perm.id,
			userId: this.userId,
			action: (event.target.checked ? 'GRANT' : 'REVOKE')
		}
		this.setState({ user: null })
		API.shared.post('permissions/change', params).then(data => {
			this.loadData()
		})
	}

	render() {
		//let selectedCompanyIds = this.state.reservaCompanies.map(company => company.value)
		if(!(this.state && this.state.user && this.state.reservaCompanies)) {
			return <Layout isLoading/>
		}
		const companyOptions = Object.keys(API.shared.companies).map(id => ({
			value: id,
			label: API.shared.companies[id].name,
		}));

		const u = this.state.user
		return (
			<UserLayout title={ this.isNew ? 'Create New User' : u.name } center>
				<div className="InnerBox">
					<Form onSubmit={ formData => this.onSubmit(formData) }>
						<div className="UserImage" style={ { backgroundImage: 'url(' + u.imagePath + ')' } }/>
						<h2>Profile</h2>
						{/* <div className="UserImage"/>
						<FileInput label="Change Picture" name="changePicture"/> */}
						<TextInput label="Name" name="name" defaultValue={ u.name }/>
						<TextInput label="Email" name="email" defaultValue={ u.email }/>
						<TextInput label="Phone" name="phone" defaultValue={ u.phone }/>
						<TextInput label="Draugiem ID" name="draugiemId" defaultValue={ u.draugiemId }/>
						<Checkbox label="Remote" name="isRemote" defaultValue={ u.isRemote }/>
						<Checkbox label="Reserva Admin" name="isReservaAdmin" defaultValue={ u.isReservaAdmin } onChange={event => this.handleIsAdminChange(event)}/>
						{this.state.isReservaAdmin ? (
							<ReactSelect
								name="reservaCompanies"
								isMulti
								options={companyOptions}
								value={this.state.reservaCompanies}
								onChange={values => this.handleReservaCompaniesChange(values)}
							/>
						) : null}
						<Submit submit label={ this.isNew ? 'Create User' : 'Save Changes' } style={{marginTop: '10px'}}/>
					</Form>
					{ !this.isNew &&
						<div className="CompanyEditor">
							<h2>Company <button onClick={ e => this.onAddCompany() }>+</button></h2>
							<div className="Entries">
								{ u.companies.map((company, idx) =>
									<div key={ idx } className="CompanyEditorEntry" onClick={ e => this.onEditCompany(company) }>
										<div className="Name">
											{ API.shared.companyName(company.companyId) }
										</div>
										{ company.email &&
											<div className="Email">
												{ company.email }
											</div>
										}
										{ company.active ? <span className="Active">(Active)</span> : <span className="Inactive">(Inactive)</span> }
									</div>
								) }
							</div>
						</div>
					}
					{ this.state.globalPermissions &&
						<div className="PermissionEditor">
							<h2>Permissions</h2>
							{ this.state.globalPermissions.map(p => {
								const hasIt = (u.permissions.indexOf(p.id) !== -1)
								const forced = (hasIt && u.grantedPermissions.indexOf(p.id) === -1)
								return (
									<div className="Permission" key={ p.id }>
										<label>
											{ <input type="checkbox" checked={ hasIt } onChange={ e => this.togglePermission(e, p) } /> }
											&nbsp;
											{ p.name }
										</label>
									</div>
								)
							})}
						</div>
					}
				</div>
			</UserLayout>
		)
	}
}
