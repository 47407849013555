
// ===================================================================

import React from 'react'
import styled from 'styled-components'

import API from '../API'

// ===================================================================

const LoginStyle = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	background: #2c2c2c;
	color: white;
	font-size: 14px;
	.Icon {
		font-size: 50px;
		text-align: center;
	}
	.Box {
		margin-bottom: 50%;
		width: 350px;
		& > .Header {
			background: transparent;
			color: #626262;
			text-align: center;
			box-shadow: none;
		}
		& > .Content {
			color: #a3a3a3; //#2c2c2c  626262
			margin-top: 10px;
			padding: 10px;
			font-size: 14px;
		}
	}
	input {
		color: white;
		width: 100%;
		padding: 12px;
		font-size: 14px;
		border: 1px solid #626262 !important;
		border-radius: 6px;
		margin: 10px 0;
		background: #262626;
		box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
		transition: all ease-in-out 0.2s;
	}
	
	input:focus {
		outline: none;
		box-shadow: 0px 0px 10px 0px #57534e !important;
	}
	button {
		margin-top: 10px !important;
		background: #0E9F6E;
		border: none !important;
		color: white;
		padding: 16px 20px;
		font-weight: bold;
		font-size: 14px;
		width: 100%;
		border-radius: 6px;
		cursor: pointer;
		box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3) !important;
		transition: all ease-in-out 0.2s;
	}
	
	button:hover {
		background: #31C48D;
	}
`

// ===================================================================

export default class Login extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			email: null,
			error: null
		}
	}
	onEmailSubmit(e) {
		e.preventDefault()
		const email = this.refs.emailInput.value
		API.shared.requestCode(email).then(() => {
			this.setState({ email })
		}).catch(err => {
			alert('We were unable to verify your email! Please check if you entered your email correctly!')
		}) 
	}
	onCodeSubmit(e) {
		e.preventDefault()
		const code = this.refs.codeInput.value
		API.shared.validateCode(this.state.email, code).then(() => {
			// not used...
		}).catch(err => {
			alert('Invalid Code!')
		}) 
	}
	render() {
		let content = null
		let title = null

		if(!this.state.email) {
			title = 'Login'
			content = (
				<form key="emailInput" onSubmit={ e => this.onEmailSubmit(e) }>
					<p>Enter your email</p>
					<input type="email" placeholder="Your email" ref="emailInput" autoFocus/>
					<br/>
					<button type="submit">Login</button>
				</form>
			)
		} else {
			title = 'Verify it\'s you'
			content = (
				<form key="codeInput" onSubmit={ e => this.onCodeSubmit(e) }>
					<p>
						We've sent a verification code to your phone.<br/>
						Enter the code from the SMS in the field below.
					</p>
					<input type="text" placeholder="Enter 6 digit code" ref="codeInput" autoFocus/>
					<br/>
					<button type="submit">Verify</button>
				</form>
			)
		}

		return (
			<LoginStyle>
				<div>
					<div className="Icon">
						<img src="https://hex.lv/hex-logo.png" alt="" width="160px"/>
					</div>
					<div className="Box">
						<div className="Header">
							<h1>{ title }</h1>
						</div>
						<div className="Content">
							{ content }
						</div>
					</div>
				</div>
			</LoginStyle>
		)
	}
}

// ===================================================================
