
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Layout from '../components/Layout'
import API from '../API'

const UsersLayout = styled(Layout)`
	table {
		width: 100%;
		border-collapse: collapse;
		td {
			padding: 4px;
			border: 1px solid #999;
			font-size: 13px;
		}
		tr {
			cursor: pointer;
			&:hover td {
				background: #ccc;
			}
		}
		.InactiveUser {
			color: #999;
		}
		.CompanyLabel {
			font-size: 12px;
			background: #ccc;
			color: #888;
			padding: 3px 6px;
			margin: 2px;
			&.active {
				background: #4595fd;
				color: white;
			}
			span {
				font-size: 10px;
				opacity: 0.5;
			}
		}
		.Label {
			display: inline;
			padding-left: 5px;
			font-size: 10px;
			font-weight: bold;
			color: #777;
		}
	}
	.Toolbar {
		background: white;
		padding: 5px;
		margin-bottom: 15px;
		border: 1px solid #ccc;
		display: flex;
		align-items: center;
		& > div {
			margin-right: 10px;
		}
		.ButtonWrap a {
			margin-bottom: 0;
		}
	}
`

const CompanyLabel = props => {
	const className = [ 'CompanyLabel' ]
	if(props.company.active) {
		className.push('active')
	}
	return (
		<span className={ className.join(' ') }>
			{ API.shared.companyName(props.company.companyId) }
			{ props.company.email && (props.company.email != props.defaultEmail) && <span> ({ props.company.email })</span> }
		</span>
	)
}

export default class Users extends React.Component {
	constructor(props) {
		super(props)
		this.state = { showActiveOnly: true }
	}
	componentDidMount() {
		this.loadData()
	}
	loadData() {
		API.shared.get('users').then(data => {
			this.setState({ users: data.users })
		})
	}
	toggleActive(e) {
		this.setState({ showActiveOnly: e.target.checked })
	}
	render() {
		if(!(this.state && this.state.users)) {
			return <Layout isLoading/>
		}
		return (
			<UsersLayout title="Users">
				<div className="Toolbar">
					<div className="ButtonWrap">
						<Link to="/users/new">Create User</Link>
					</div>
					<div>
						<label><input type="checkbox" checked={ this.state.showActiveOnly } onChange={ e => this.toggleActive(e) }/> Show Only Active Users</label>
					</div>
				</div>
				<table>
					<thead>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Phone</th>
						<th>E-Mail</th>
						<th>Company</th>
					</tr>
					</thead>
					<tbody>
					{ this.state.users.map(user => {
						if(this.state.showActiveOnly && !user.active) {
							return null
						}
						return (
							<tr key={ user.id } onClick={ e => this.props.history.push('/users/' + user.id) }>
								<td>{ user.id }</td>
								<td className={ user.active ? 'ActiveUser' : 'InactiveUser' }>
									{ user.name }
									{ user.isRemote && <div className="Label">[REMOTE]</div> }
								</td>
								<td>+{ user.phone }</td>
								<td>{ user.email }</td>
								<td>
									{ user.companies.map(c => <CompanyLabel key={ c.companyId } defaultEmail={ user.email } company={ c }/> ) }
								</td>
							</tr>
						)
					} )}
					</tbody>
				</table>
			</UsersLayout>
		)
	}
}
