
import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import MeetingRoomsImg from '../images/MeetingRooms.png'

const MeetingRoomsLayout = styled(Layout)`
	.Content {
		img {
			width: 100%;
		}
	}
`

export default class MeetingRooms extends React.Component {
	render() {
		return (
			<MeetingRoomsLayout title="Meeting Rooms">
				<img src={MeetingRoomsImg} />
			</MeetingRoomsLayout>
		)
	}
}
