
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Layout from '../components/Layout'
import API from '../API'

const ProfileLayout = styled(Layout)`
	.InnerBox {
		text-align: center;
	}
	.UserImage {
		width: 150px;
		height: 150px;
		background: gray;
		background-size: cover;
		background-position: center center;
		border-radius: 60px;
		margin: 0 auto;
		margin-bottom: 10px;
	}
	.ChangeUserImageReal {
		visibility: hidden;
		display: block;
	}
`

export default class Profile extends React.Component {
	onFileSelect(e) {
		const file = e.target.files[0]
		if(!file) {
			return
		}

		const formData = new FormData()
		formData.append('file', file)

		API.shared.post('upload-picture', formData).then(data => {				
			console.log({ data })
			location.reload()
		})
	}
	onChangeClick() {
		this.refs.ChangeUserImageReal.click()
	}
	render() {
		const u = API.shared.user
		return (
			<ProfileLayout title="My Profile">
				<div className="InnerBox">
					<div className="UserImage" style={ { backgroundImage: 'url(' + u.imagePath + ')' } }/>
					<button className="ChangeUserImage" onClick={ () => this.onChangeClick() }>Upload New Picture</button>
					<input className="ChangeUserImageReal" ref="ChangeUserImageReal" type="file" onChange={ e => this.onFileSelect(e) }/>
				</div>
			</ProfileLayout>
		)
	}
}
