
import React from 'react'
import styled from 'styled-components'
import Quagga from 'quagga'

import Layout from '../components/Layout'

const LibraryLayout = styled(Layout)`
	#scanHolder {
		border: 3px solid red;
		width: 300px;
		height: 400px;
	}
`

export default class Library extends React.Component {

	componentDidMount() {

		console.log('did mount')

		this.rf = () => {
			console.log("Called when ready");
		}

		Quagga.init({
			inputStream: {
				name: "Live",
				type: "LiveStream",
				target: document.getElementById('scanHolder'),
				constraints: {
					width: 300,
					height: 400,
					facingMode: 'environment'
				}
			},
			numOfWorkers: 4,
			decoder: {
				readers: ["code_128_reader"]
			},
			readyFunc: this.rf
		}, function (err) {
			console.log('INIT ERROR =', err)
			if (err) {
				console.log('ccx', err);
				return
			}
			console.log("Initialization finished. Ready to start");
			Quagga.start();
		});

		Quagga.onProcessed(result => {
			console.log('processed', result)
			document.getElementById('result').textContent = JSON.stringify(result)
		})

	}

	render() {
		return (
			<LibraryLayout title="Library">
				Welcome to Library
				<div id="scanHolder"></div>
				<div id="result">...</div>
			</LibraryLayout>
		)
	}

}
