
// ===================================================================

import React, {useState} from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import API from '../API'

// ===================================================================
// Style
// ===================================================================

const KeychainLayout = styled(Layout)`
	.Group {
		margin-bottom: 10px;
		& > .Name {
			border-bottom: 2px solid #eee;
			padding-bottom: 4px;
			margin-bottom: 4px;
			font-weight: bold;
			color: #999;
			font-size: 18px;
		}
		.Keys {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			.Icon {
				display: block;
				font-size: 20px;
				margin-bottom: 10px;
			}
			.Name {
				font-size: 14px;
				white-space: pre;
				font-weight: bold;
			}
			.Key {
				text-align: center;
				display: flex;
				justify-content: center;
				flex-direction: column;
				background: #ddd;
				padding: 10px 10px;
				margin: 5px;
				border-radius: 10px;
				min-width: 100px;
				flex-grow: 1;
				cursor: pointer;
				box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
				&.busy {
					opacity: 0.3;
				}
				&.isOpen {
					background: #aaff66;
				}
				&.size-large {
					min-width: 100%;
				}
			}
		}
	}
	.KCPrintfulNotice {
		background: #ffff88;
		border: 1px solid #aaaa22;
		padding: 15px;
		margin-bottom: 30px;
		line-height: 23px;
		border-radius: 4px;
		.split {
			margin: 20px 0;
			border-top: 1px solid #00000066;
		}
		a {
			color: black;
			font-weight: bold;
			text-decoration: underline;
		}
	}

	/* The switch - the box around the slider */
	.switch {
		position: relative;
		display: inline-block;
		width: 55px;
		height: 28px;
	}

	/* Hide default HTML checkbox */
	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	/* The slider */
	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: .4s;
		transition: .4s;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 20px;
		width: 20px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: .4s;
		transition: .4s;
	}

	input:checked + .slider {
		background-color: #2196F3;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #2196F3;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(26px);
		-ms-transform: translateX(26px);
		transform: translateX(26px);
	}
	
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
	
	.shellySwitch {
		color: #999;
		font-size: 16px;
		font-weight: bold;
		padding-right: 4px;
		align-self: center;
	}
	.shellyApiToggle {
		display: flex;
		justify-content: end;
		align-items: center;
		align-content: center;
	}
`

// ===================================================================
// View Component
// ===================================================================

export default class Keychain extends React.Component {
	constructor(props) {
		super(props)
		this.updateTimer = null
	}
	componentDidMount() {
		this.scheduleUpdate(0)
	}
	componentWillUnmount() {
		this.scheduleUpdate(-1)
	}
	scheduleUpdate(sec) {
		console.log('Keychain :: scheduleUpdate', sec)
		if (this.updateTimer) {
			clearTimeout(this.updateTimer)
			this.updateTimer = null
		}
		if (sec >= 0) {
			this.updateTimer = setTimeout(() => {
				this.loadData()
			}, (sec * 1000))
		}
	}
	loadData() {
		API.shared.get('keychain').then(data => {
			this.setState({ data })
		})
	}
	useKey(key, callback) {
		console.log('Keychain :: useKey', key.id)
		API.shared.post('keychain/use-key', { id: key.id, toggleApi: API.shared.useShellyApi }).then(data => {
			callback(data && data.success)
			this.scheduleUpdate(3)
		})
	}
	render() {
		if (!this.state) {
			return <Layout isLoading />
		}
		let hasPrintful = false
		let showNotice = false
		for (let c of API.shared.user.companies) {
			if (c.companyId == 25) {
				hasPrintful = true
				break
			}
		}
		return (
			<KeychainLayout title="Keychain">
				{ <ShellyApiToggle value={API.shared.useShellyApi} /> }
				{ hasPrintful && showNotice && <KCPrintfulNotice />}
				{ this.state.data.keyGroups.map(group => <KCGroup key={group.name} group={group} keychain={this} />)}
			</KeychainLayout>
		)
	}
}

// ===================================================================
// Subcomponents
// ===================================================================

function ShellyApiToggle() {
	const [isChecked, setIsChecked] = useState(true);
	API.shared.useShellyApi = isChecked;

	const handleToggle = () => {
		setIsChecked(!isChecked);
		API.shared.useShellyApi = !API.shared.useShellyApi;
	};

	return (
		<div className="shellyApiToggle">
			<span className="shellySwitch">Local API</span>
			<label className="switch">
				<input type="checkbox" checked={isChecked} onClick={handleToggle} />
				<span className="slider round"></span>
			</label>
		</div>
	);
}

function KCPrintfulNotice(props) {
	return (
		<div className="KCPrintfulNotice">
			LV: Sakarā ar pāriešanu no HEX Keychain uz Sherlockist kā Printful durvju atvēršanas risinājumu, lūdzu, turpmāk izmantojiet Sherlockist mobilo lietotni (ja lietotne prasa Sherlockist server adresi, tad ievadiet printful.com). Ja kādu iemeslu dēļ lietotni neizdodas uzstādīt, lūdzu, izmantojiet: <a href="https://keychain.printful.com/" target="_blank">keychain.printful.com</a>
			<br /><br />
			<a href="https://apps.apple.com/us/app/sherlockist/id1551784419" target="_blank">Iegūt Sherlockist lietotni Apple iPhone telefoniem.</a><br />
			<a href="https://play.google.com/store/apps/details?id=com.printful.keychain&hl=en_US&gl=US" target="_blank">Iegūt Sherlockist lietotni Android telefoniem.</a>
			<div className="split" />
			EN: Due to the transition from HEX Keychain to Sherlockist as a Printful door opening solution, please use the Sherlockist mobile application (if the application asks for Sherlockist server address, enter printful.com). If for some reason the application fails to install, please use: <a href="https://keychain.printful.com/" target="_blank">keychain.printful.com</a>
			<br /><br />
			<a href="https://apps.apple.com/us/app/sherlockist/id1551784419" target="_blank">Get the Sherlockist app for Apple iPhones.</a><br />
			<a href="https://play.google.com/store/apps/details?id=com.printful.keychain&hl=en_US&gl=US" target="_blank">Get the Sherlockist app for Android phones.</a>
		</div>
	)
}

// - - -

function KCGroup(props) {
	return (
		<div className="Group">
			<div className="Name">{props.group.name}</div>
			<div className="Keys">
				{props.group.keys.map(key => <KCKey key={key.name} theKey={key} keychain={props.keychain} />)}
			</div>
		</div>
	)
}

// - - -

class KCKey extends React.Component {
	constructor(props) {
		super(props)
		this.state = { busy: false }
	}
	useKey() {
		if (this.state.busy) {
			return
		}
		this.setState({ busy: true })
		this.props.keychain.useKey(this.props.theKey, success => {
			setTimeout(() => {
				this.setState({ busy: false })
			}, 3000)
		})
	}
	render() {
		const classNames = ['Key']
		let icon = 'lock'
		if (this.props.theKey.state) {
			icon = 'lock'
		}
		if (this.state.busy) {
			classNames.push('isOpen')
			classNames.push('busy')
			icon = 'unlock'
		}

		if (this.props.theKey.momentary) {
			icon = 'key'
		}
		if (this.props.theKey.size) {
			classNames.push('size-' + this.props.theKey.size)
		}
		return (
			<div className={classNames.join(' ')} onClick={e => this.useKey(e)}>
				<i className={'Icon fas fa-' + icon} />
				<div className="Name">{this.props.theKey.name}</div>
			</div>
		)
	}
}

// ===================================================================
