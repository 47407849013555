import React, {useCallback, useEffect, useState} from "react";
import Gallery from "react-photo-gallery";
import SelectedImage from "./Selected";


const Monitor = ({ url, title, monitorId, type }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [rotationTime, setRotationTime] = useState(4);
    const [images, setImages] = useState([]);
    const [monitorSize, setMonitorSize] = useState({ width: 360, height: 640 });

    const monitorStyle = {
        width: type === 'horizontal' ? monitorSize.height : monitorSize.width,
        height: type === 'horizontal' ? monitorSize.width : monitorSize.height,
        background: "#222",
        borderRadius: "8px",
        paddingTop: "6px",
        paddingBottom: "6px",
        cursor: "pointer",
        filter: isHovered ? "brightness(0.5)" : "brightness(1)",
        transition: "all 0.2s ease-in-out",
        zIndex: "0",
    };

    const iframeStyle = {
        transform: isHovered ? "" : "scale(0.97)", // Scale down when not hovered
        filter: isHovered ? "grayscale(1) blur(2px)" : "none", // Apply blur when hovered
        pointerEvents: "none"
    };

    const overlay = {
        position: "relative",
        width: type === 'horizontal' ? monitorSize.height : monitorSize.width,
        height: type === 'horizontal' ? monitorSize.width : monitorSize.height,
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // Center vertically
        alignItems: "center", // Center horizontally
        pointerEvents: "auto",
    };

    const titleStyle = {
        position: "absolute",
        top: "5%",
        zIndex: "1",
        pointerEvents: "none",
        color: '#fff',
        transition: "all 0.2s ease-in-out",
        display: isHovered ? 'block' : 'none',
        textShadow: '1px 1px 5px rgba(0, 0, 0, 0.5)',
    };

    const changeHeading = {
        display: isHovered ? 'block' : 'none',
    }

    useEffect(() => {
        // Function to update monitor size based on window width
        const baseWidth = 360 * 0.65;
        const baseHeight = 640 * 0.65;

        const updateMonitorSize = () => {
            const screenWidth = window.innerWidth;
            let scaledWidth, scaledHeight;

            if (screenWidth < baseWidth) {
                scaledWidth = screenWidth;
                scaledHeight = (screenWidth / baseWidth) * baseHeight;
            } else {
                scaledWidth = baseWidth;
                scaledHeight = baseHeight;
            }

            setMonitorSize({ width: scaledWidth, height: scaledHeight });
        };

        updateMonitorSize();
        window.addEventListener("resize", updateMonitorSize);

        return () => {
            window.removeEventListener("resize", updateMonitorSize);
        };
    }, []);

    const handleEscapeKey = (event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
           setIsModalOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, []);


    const handleToggleModal = () => {
        setModalLoading(true);
        const fetchImages = async () => {
            try {
                const response = await fetch('https://hex.lv/chromecast/?getImages', {
                    method: 'GET',
                })
                const data = await response.json();

                if(data) {
                    setImages([]);
                    data.map((image) => {
                        setImages((prevState) => [
                            ...prevState,
                            {
                                src: image.url,
                                width: parseInt(image.width),
                                height: parseInt(image.height),
                                key: String(image.id)
                            }
                        ])
                    });
                    setModalLoading(false);
                }
            } catch (error) {
                console.error(error);
            }
        }

        fetchImages()
            .catch(err => console.error(err))

        setIsModalOpen(!isModalOpen);
    }

    const imageRenderer = useCallback(({ index, left, top, key, photo }) => {
        console.log('Key:', key);

        return (
            <SelectedImage
                selected={selectAll}
                imageKey={key}
                margin={"2px"}
                index={index}
                photo={photo}
                left={left}
                top={top}
                onSelectedKey={handleSelectedKey}
            />
        );
    }, [selectAll]);

    const handleSelectedKey = (key) => {
        key = parseInt(key);
        setSelectedImages((prevSelectedImages) => {
            if (prevSelectedImages.includes(key)) {
                return prevSelectedImages.filter((image) => image !== key);
            } else {
                return [...prevSelectedImages, key];
            }
        });
    }

    const handleSaveRotation = () => {
        console.log(selectedImages);
        console.log(rotationTime);

        const saveRotation = async () => {
            try {
                const formData = new FormData();
                formData.append('keys', selectedImages);
                formData.append('time', rotationTime * 1000);
                formData.append('monitor', monitorId);
                const response = await fetch('https://hex.lv/chromecast/', {
                    method: 'POST',
                    body: formData,
                });

                const responseJson = await response.json();
                console.log(responseJson);

            } catch (error) {
                console.error(error);
            }
            fetch('http://194.19.232.194:3388/chromecast/chromecaster/?refresh=true', {
                method: 'GET',
                cors: 'no-cors',
            })
        }

        saveRotation();
        window.location.reload();
    }

    return (
        <>
            <div style={overlay}
                 onMouseEnter={() => setIsHovered(true)}
                 onMouseLeave={() => setIsHovered(false)}
                 onClick={() => handleToggleModal()}
            >
                <h1 style={titleStyle}>
                    {title}
                </h1>
                <h2 className="hoverText" style={changeHeading}>
                    New Rotation
                </h2>
                <iframe
                    src={url}
                    style={{ ...monitorStyle, ...iframeStyle }}
                    title="Chromecast feed"
                ></iframe>
            </div>
            {isModalOpen && (
                <div
                    className="modal"
                    onClick={() => handleToggleModal()}
                >
                    <div className={'modalContent'}
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            maxHeight: '80%',
                            overflow: 'scroll'
                    }}>
                        <div className="modalHeader">
                            <h3>Select images for monitor {title}</h3>
                            <span>
                                <input className="seconds"
                                       id="seconds"
                                       type="number"
                                       placeholder="seconds"
                                       defaultValue={4}
                                       onChange={(e) => setRotationTime(e.target.value)}
                                />
                                 <label htmlFor="seconds" className="secondsLabel">Sec.</label>
                                <button onClick={handleSaveRotation}>Save rotation</button>
                            </span>
                        </div>

                        {modalLoading ? (
                            <div className="loading">
                                <span>loading...</span>
                            </div>
                        ) : (
                            <Gallery
                                id={monitorId}
                                photos={images}
                                renderImage={imageRenderer}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Monitor;