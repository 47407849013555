
// ===============================================================

import React from 'react'
import styled from 'styled-components'

// ===============================================================

const FormStyle = styled.form`
	input, select {
		background-color: hsl(0, 0%, 100%);
		border-color: hsl(0, 0%, 80%);
		border-radius: 4px;
		border-style: solid;
		border-width: 1px;
		box-sizing: border-box;
		width: 100%;
		margin: 2px;
		margin-bottom: 10px;
		padding: 8px;
		color: hsl(0, 0%, 20%);
		font-family: Helvetica,sans-serif;
		font-size: 15px;
	}
	.Label {
		font-size: 14px;
		font-weight: bold;
		color: #777;
		margin-bottom: 2px;
		display: block;
	}
	.Options {
		border-top: 1px solid #eee;
		padding-top: 10px;
		text-align: right;
	}
	button {
		border: 1px solid black;
		background: #1373ec;
		color: white;
		margin: 2px;
		padding: 5px 10px;
		font-weight: bold;
		font-size: 15px;
	}
	input[type=checkbox] {
		position: relative;
		-webkit-appearance: none;
		outline: none;
		width: 50px;
		height: 30px;
		background-color: #fff;
		border: 1px solid #D9DADC;
		border-radius: 50px;
		box-shadow: inset -20px 0 0 0 #fff;
		&:after {
			content: "";
			position: absolute;
			top: 1px;
			left: 1px;
			background: transparent;
			width: 26px;
			height: 26px;
			border-radius: 50%;
			box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
		}
		&:checked {
			box-shadow: inset 20px 0 0 0 #4ed164;
			border-color: #4ed164;
		}
		&:checked:after {
			left: 20px;
			box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
		}
	}
`

const FormContext = React.createContext()

export class Form extends React.Component {
	constructor(props) {
		super(props)
		this.state = { form: this, busy: false, message: null, error: false }
		this.inputs = []
	}
	onSubmit(e) {
		e.preventDefault()
		let valueObj = {}
		for(const input of this.inputs) {
			if(input.props.name === undefined) {
				continue
			}
			valueObj[input.props.name]	= input.getValue()
		}
		if(this.props.formData) {
			const formData = new FormData()
			for(const k in valueObj) {
				let v = valueObj[k]
				if(v instanceof FileList) {
					if(v.length == 0) {
						continue
					}
					v = v[0]
				}
				formData.append(k, v)
			}
			valueObj = formData
		}
		if(this.props.onSubmit) {
			this.setState({ busy: true, message: null, error: false })
			let promise = null
			try {
				promise = this.props.onSubmit(valueObj)
				Promise.resolve(promise).then(ret => {
					this.setState({ busy: false, message: ret, error: false })
				}).catch(err => {
					this.setState({ busy: false, message: err, error: true })
				})
			} catch(err) {
				this.setState({ busy: false, message: err, error: true })
			}
		}
	}
	registerInput(input) {
		if(this.inputs.indexOf(input) == -1) {
			this.inputs.push(input)
		}
	}
	unregisterInput(input) {
		const idx = this.inputs.indexOf(input)
		if(idx !== -1) {
			this.inputs.splice(idx, 1)
		}
	}
	render() {
		return (
			<FormStyle onSubmit={ e => this.onSubmit(e) }>
				<FormContext.Provider value={ { form: this, formState: this.state } }>
					{ this.state.message &&
						<div style={ { color: this.state.error ? 'red' : 'green' }}>
							{ this.state.message }
						</div>
					}
					{ this.props.children }
				</FormContext.Provider>
			</FormStyle>
		)
	}
}

// ===============================================================

export function InputGroup(props) {
	return (
		<div>
			{ props.label && <label className="Label">{ props.label }</label> }
			{ props.children }
		</div>
	)
}

class Input extends React.Component {
	constructor(props) {
		super(props)
		this.inputRef = React.createRef()
	}
	componentWillUnmount() {
		if(this.formContext) {
			this.formContext.form.unregisterInput(this)
		}
	}
	render() {
		return (
			<FormContext.Consumer>
				{ formContext => {
					this.formContext = formContext
					this.formContext.form.registerInput(this)
					return this.renderInput(formContext)
				}}
			</FormContext.Consumer>
		)
	}
	getValue() {
		if(this.props.type == 'checkbox') {
			return this.inputRef.current.checked
		} else if(this.props.type == 'file') {
			return this.inputRef.current.files
		}
		return this.inputRef.current.value
	}
	renderInput(formContext) {
		let inputProps = {
			...this.props,
			ref: this.inputRef,
			disabled: formContext.formState.busy
		}
		let labelValue = this.props.label
		let inputElement = null
		if(this.props.type == 'checkbox') {
			inputProps.defaultChecked = inputProps.defaultValue
			delete inputProps.defaultValue
		} else if(this.props.type == 'select') {
			let options = inputProps.options
			delete inputProps.options
			inputElement = (
				<select { ...inputProps }>
					{ Object.keys(options).map(key =>
						<option key={ key } value={ key }>{ options[key] }</option>
					)}
				</select>
			)
		} else if(this.props.type == 'submit') {
			let buttonLabel = labelValue
			if(formContext.formState.busy) {
				buttonLabel = 'Please Wait...'
			}
			labelValue = null
			if(inputProps.submit) {
				inputProps.submit = 'true'
			}
			inputElement = (
				<button { ...inputProps}>{ buttonLabel }</button>
			)
		} else if(this.props.type == 'textarea') {
			inputElement = (
				<textarea { ...inputProps}/>
			)
		}
		if(!inputElement) {
			inputElement = <input { ...inputProps }/>
		}
		return <InputGroup label={ labelValue }>{ inputElement }</InputGroup>
	}
}

// ===============================================================

export const TextInput = (props) => <Input type="text" { ...props }/>
export const PasswordInput = (props) => <Input type="password" { ...props }/>
export const Checkbox = (props) => <Input type="checkbox" { ...props }/>
export const Submit = (props) => <Input type="submit" { ...props }/>
export const Select = (props) => <Input type="select" { ...props }/>
export const FileInput = (props) => <Input type="file" { ...props }/>
export const TextArea = (props) => <Input type="textarea" { ...props }/>
export const HiddenInput = (props) => <Input type="hidden" { ...props }/>

// ===============================================================
