
import Keychain from './views/Keychain'
import User from './views/User'
import Users from './views/Users'
import Profile from './views/Profile'
import MeetingRooms from './views/MeetingRooms'
import Library from './views/Library'
import Monitors from "./views/Monitors";
import Tools from "./views/Tools";

export default [
	{
		path: '/keychain',
		component: Keychain,
		sideMenu: {
			title: 'Keychain',
			icon: 'fas fa-key'
		}
	},
	{
		path: '/resources',
		component: Tools,
		sideMenu: {
			title: 'Employee Resources',
			icon: 'fas fa-shapes'
		}
	},
	{
		path: '/meeting-rooms',
		component: MeetingRooms,
		sideMenu: {
			title: 'Meeting Rooms',
			icon: 'far fa-clock'
		},
	},
	 {
	 	path: '/chromecast',
	 	component: Monitors,
	 	sideMenu: {
	 		title: 'Chromecast',
	 		icon: 'fas fa-tv'
	 	},
		 requiresPermission: ['MONITOR_ADMIN']
	 },
	{
		path: '/users',
		component: Users,
		sideMenu: {
			title: 'Users',
			icon: 'fas fa-users'
		},
		requiresPermission: ['SUPER_ADMIN', 'DG_USER_ADMIN', 'PF_USER_ADMIN']
	},
	{
		path: '/users/:userId',
		component: User,
		requiresPermission: ['SUPER_ADMIN', 'DG_USER_ADMIN', 'PF_USER_ADMIN']
	},
	{
		path: '/profile',
		component: Profile,
		sideMenu: {
			title: 'My Profile',
			icon: 'fas fa-user'
		}
	},
]
