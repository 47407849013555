
export default class BaseAPI {

	static get shared() {
		if(!this.sharedInstance) {
			this.sharedInstance = new this()
		}
		return this.sharedInstance
	}

	get(rq, args) {
		return this.rq('GET', rq, args)
	}

	post(rq, args) {
		return this.rq('POST', rq, args)
	}

	rq(method, rq, args) {
		let rqArgs = {
			credentials: 'same-origin'
		}
		const rqUrl = document.location.origin + '/api/' + rq
		if(method == 'GET') {
			rqArgs.method = 'GET'
			// TODO: add args ?&
		} else if(method == 'POST') {
			rqArgs.method = 'POST'
			if(args && typeof(args) === 'object') {
				if(args instanceof FormData) {
					rqArgs.body = args
				} else {
					rqArgs.headers = {
						'Content-Type': 'application/json'
					}
					rqArgs.body = JSON.stringify(args)
				}
			}
		} else {
			throw 'Method not supported!'
		}
		return fetch(rqUrl, rqArgs).then(response => response.json())
	}

}
