
// ===================================================================

import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import SideMenu from './SideMenu'
import Modal, { ModalContainer } from './Modal'

// ===================================================================

const LayoutStyle = styled.div`
	min-height: 100%;
	.SideMenu {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		width: 250px;
		overflow: auto;
		z-index: 1000;
	}
	.ContentBlackout,
	.SideMenuButton {
		display: none;
	}
	.MainContent {
		margin-left: 250px;
		overflow: auto;
		& > .Header {
			padding: 20px;
			display: flex;
			align-items: center;
			h1 {
				font-size: 30px;
			}
		}
		& > .Loading {
			font-weight: bold;
			color: #999;
			padding: 30px 0;
			text-align: center;
		}
		& > .Content {
			padding: 20px;
		}
	}
	&.layoutCenter {
		.MainContent > .Content {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
		}
	}
	/* MOBILE Layout */
	@media (max-width: 700px) {
		.SideMenu {
			box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
			display: none;
		}
		.SideMenuButton {
			padding: 10px;
			margin-left: -10px;
			margin-right: 10px;
			display: block;
		}
		.ContentBlackout {
			display: none;
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: black;
			opacity: 0.6;
			z-index: 900;
		}
		.MainContent {
			margin-left: 0;
		}
		&.sideMenuOpen {
			.SideMenu {
				display: block;
			}
			.SideMenuButton {
			}
			.ContentBlackout {
				display: block;
			}
		}
	}
`

// ===================================================================

class LayoutClass extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			sideMenuOpen: false
		}
	}
	componentDidUpdate(prevProps) {
		if(this.props.location !== prevProps.location) {
			this.hideSideMenu()
		}
	}
	hideSideMenu() {
		this.setState({ sideMenuOpen: false })
	}
	toggleSideMenu() {
		this.setState({ sideMenuOpen: !this.state.sideMenuOpen })
	}
	render() {
		const classNames = [ this.props.className ]
		if(this.state.sideMenuOpen) {
			classNames.push('sideMenuOpen')
		}
		if(this.props.center) {
			classNames.push('layoutCenter')
		}
		return (
			<LayoutStyle className={ classNames.join(' ') }>
				<div className="SideMenu">
					<SideMenu/>
				</div>
				<div className="ContentBlackout" onClick={ e => this.hideSideMenu(e) }/>
				<div className="MainContent">
					{ this.props.title  &&
						<div className="Header">
							<div className="SideMenuButton" onClick={ e => this.toggleSideMenu(e) }>
								<i className="fas fa-bars"/>
							</div>
							<h1>{ this.props.title }</h1>
						</div>
					}
					{ this.props.isLoading && <div className="Loading">Loading...</div> }
					{ !this.props.isLoading && <div className="Content">{ this.props.children }</div> }
				</div>
				<ModalContainer/>
			</LayoutStyle>
		)
	}
}

export default function Layout(props) {
	return <LayoutClass { ...props }/>
}

// ===================================================================
