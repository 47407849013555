import React, { useState, useEffect } from "react";

const Checkmark = ({ selected }) => (
    <div
        style={
            selected
                ? { left: "-4px", top: "4px", position: "absolute", zIndex: "1" }
                : { display: "none" }
        }
    >
        <svg
            style={{ fill: "white", position: "absolute" }}
            width="40px"
            height="40px"
        >
            <circle cx="12.5" cy="12.2" r="8.292" />
        </svg>
        <svg
            style={{ fill: "#1472ec", position: "absolute" }}
            width="40px"
            height="40px"
        >
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
        </svg>
    </div>
);

const imgStyle = {
    transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
};
const selectedImgStyle = {
    transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
    transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
    display: "block",
    margin: "auto",
    opacity: "0.7"
};
const cont = {
    backgroundColor: "white",
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
    display: "flex",    // Use flexbox for centering vertically and horizontally
    justifyContent: "center",  // Center horizontally
    alignItems: "center"

};

const SelectedImage = ({
                           index,
                            imageKey,
                           photo,
                           margin,
                           direction,
                           top,
                           left,
                           selected,
                           onSelectedKey
                       }) => {
    const [isSelected, setIsSelected] = useState(selected);
    //calculate x,y scale
    const sx = (100 - (30 / photo.width) * 100) / 100;
    const sy = (100 - (30 / photo.height) * 100) / 100;
    selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;

    if (direction === "column") {
        cont.position = "absolute";
        cont.left = left;
        cont.top = top;
    }

    const handleOnClick = e => {
        setIsSelected(!isSelected);
        onSelectedKey(imageKey);
    };

    useEffect(() => {
        setIsSelected(selected);
    }, [selected]);

    return (
        <div
            style={{ margin, height: photo.height, width: photo.width, ...cont }}
            className={!isSelected ? "not-selected" : ""}
        >
            <Checkmark selected={isSelected} />
            <img
                alt={photo.title}
                style={
                    isSelected ? { ...imgStyle, ...selectedImgStyle } : { ...imgStyle }
                }
                {...photo}
                onClick={handleOnClick}
            />
            <style>{`.not-selected:hover{opacity: 0.8; transition: all 0.15s`}</style>
        </div>
    );
};

export default SelectedImage;