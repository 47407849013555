

import React from 'react'
import styled from 'styled-components'

const ModalContainerStyle = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 10000;
	.Modal {
		position: absolute;
		z-index: 10000;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		.ModalBlackout {
			background: black;
			opacity: 0.6;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			z-index: 10001;
			cursor: pointer;
		}
		.ModalContent {
			border-radius: 8px;
			background: white;
			padding: 0.8em;
			z-index: 10002;
		}
	}
`

export class ModalContainer extends React.Component {
	constructor(props) {
		super(props)
		window.modalContainer = this
		this.state = { modals: [] }
	}
	push(modal) {
		this.setState({ modals: [ ...this.state.modals, modal ]})
	}
	pop() {
		if(this.state.modals.length > 0) {
			this.setState({ modals: this.state.modals.slice(0, this.state.modals - 1) })
		}
	}
	render() {
		if(this.state.modals.length == 0) {
			return <React.Fragment/>
		}
		return (
			<ModalContainerStyle>
				{ this.state.modals }
			</ModalContainerStyle>
		)
	}
}

export default class Modal extends React.Component {
	static present(content) {
		window.modalContainer.push(<Modal key="thisShouldBeSomethinRandom">{ content }</Modal>)
	}
	render() {
		return (
			<div className="Modal">
				<div className="ModalContent">
					{ this.props.children }
				</div>
				<div className="ModalBlackout" onClick={ e => window.modalContainer.pop() }/>
			</div>
		)
	}
}
