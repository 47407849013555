

import BaseAPI from './BaseAPI'

export default class API extends BaseAPI {

	constructor() {
		super()
		this.user = null
		this.companies = null
		this.userStateKnown = false
	}

	getUser() {
		return new Promise((resolve, reject) => {
			if(!this.userStateKnown) {
				console.log('requesting user...')
				this.get('init').then(response => {
					if(response && response.user) {
						this.user = response.user
						const companies = {}
						for(const c of response.companies) {
							companies[c.id] = c
						}
						this.companies = companies
					} else {
						this.user = null
					}
					this.userStateKnown = true
					resolve(this.user)
				})
			} else {
				resolve(this.user)
			}
		})
	}

	hasPermission(permission) {
		if(typeof(permission) === 'string') {
			permission = [ permission ]
		}
		for(let p of permission) {
			if(this.user && this.user.permissions.indexOf(p.toUpperCase().trim()) !== -1) {
				return true
			}
		}
		return false
	}

	companyName(id) {
		if(this.companies[id]) {
			return this.companies[id].name
		}
		return '?' + id + '?'
	}

	requestCode(email) {
		this.userStateKnown = false
		return new Promise((resolve, reject) => {
			this.post('auth/req-code', { email }).then(data => {
				if(data && data.success) {
					resolve()
					return
				}
				reject()
			})
		})
	}

	validateCode(email, code) {
		return new Promise((resolve, reject) => {
			this.post('auth/validate-code', { email, code }).then(data => {
				if(data && data.user) {
					this.getUser().then(() => {
						resolve()
						this.updateApp()
					})
					return
				}
				reject()
			})
		})		
	}

	logout() {
		this.post('auth/logout').then(data => {
			this.user = null
			this.companies = null
			this.updateApp()
		})
	}

	updateApp() {
		window.theApp.forceUpdate()
	}

}
