
// ===================================================================

import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import API from '../API'

import views from '../views'

// ===================================================================

const SideMenuStyle = styled.div`
	background: #222222;
	min-height: 100%;
	color: white;
	
	
	& > .User {
		padding: 20px 15px 30px;
		& > .Image {
			font-size: 40px;
			margin-bottom: 10px;
		}
		& > .Name {
			display: flex;
			& > .Value {
				flex-grow: 1;
				font-weight: bold;
				font-size: 18px;
			}
			& > .Options {
				color: #aaa;
				cursor: pointer;
			}
		}
		& > .Company {
			& > .Entry {
				font-size: 14px;
				color: #aaa;
			}
		}
	}
	
	& > a { /* NavLink */
		color: #aaa;
		display: block;
		padding: 10px 15px;
		
		
		i {
			margin-right: 5px;
		}
		&.active {
			color: black;
			background: white;
		}
	}
`

// ===================================================================

export default class SideMenu extends React.Component {
	reservaLogin(e) {
		e.preventDefault()
		API.shared.get('auth/get-reserva-login').then(data => {
			if(data && data.redirectUrl) {
				location.href = data.redirectUrl
			}
		})
		return false
	}
	render() {
		return (
			<SideMenuStyle>
				<div className="User">
					<div className="Image">
						<i className="fas fa-user-circle"/>
					</div>
					<div className="Name">
						<div className="Value">{API.shared.user.name}</div>
						<div className="Options">
							<i className="fas fa-sign-out-alt" onClick={e => API.shared.logout()}/>
						</div>
					</div>
					<div className="Company">
						{API.shared.user.companies.map(company =>
							(company.active && <div className="Entry" key={company.companyId}><i
								className="far fa-circle"/> {API.shared.companyName(company.companyId)}</div>)
						)}
					</div>
				</div>
				{views.map(view => {
					if (!view.sideMenu) {
						return null
					}
					if (view.requiresPermission && !API.shared.hasPermission(view.requiresPermission)) {
						return null
					}
					return <MenuLink key={view.path} to={view.path} title={view.sideMenu.title}
									 icon={view.sideMenu.icon}/>
				})}
				<a href="https://hex.lv/rekviziti/" className="">
					<i className="fas fa-fw fa-info"/>
					Company Details
				</a>
				<a href="https://dg.caterevo.com/" className="" style={{paddingTop: '30px'}}>
					<i className="fas fa-fw fa-external-link-alt"/>
					Caterevo
				</a>
				<a href="#" className="" onClick={e => this.reservaLogin(e)} >
					<i className="fas fa-fw fa-external-link-alt"/>
					Reserva
				</a>
				<a href="https://1work.com/dashboard" className="">
					<i className="fas fa-fw fa-external-link-alt"/>
					1WORK
				</a>
				<a href="https://draugiemgroup.freshdesk.com/" className="">
					<i className="fas fa-fw fa-external-link-alt"/>
					Freshdesk
				</a>
			</SideMenuStyle>
		)
	}
}

function MenuLink(props) {
	return (
		<NavLink {...props}>
			<i className={"fa-fw " + props.icon}/>
			{props.title}
		</NavLink>
	)
}

// ===================================================================
